.interview-details-container {
    font-family: Arial, sans-serif;
    padding: 20px;
    max-width: 600px;
    margin: auto;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.conversation-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}

.audio-section {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}

.audio-duration {
    font-size: 14px;
    color: #555;
}

.tabs {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.tab {
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
}

.tab.active {
    background-color: #007bff;
    color: #fff;
    border-color: #007bff;
}

.transcription-section {
    font-size: 14px;
    line-height: 1.6;
}

.transcription-section p {
    margin: 8px 0;
}

.transcription-section strong {
    color: #333;
}
