.interview-card {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.interview-content {
    text-align: center;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
}

.interview-icon {
    margin-bottom: 1rem;
    font-size: 3rem;
    /* Add an icon or image here */
}

h2 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
}

p {
    margin-bottom: 2rem;
    font-size: 1rem;
    line-height: 1.5;
}

.get-started-btn {
    background: #2563eb; /* Blue button */
    color: #fff;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s ease;
}

.get-started-btn:hover {
    background: #1d4ed8; /* Darker blue on hover */
}
