
.uploadtranscript{

}

.uploadtranscript__modal{
width: 80%;
margin-left: -40%;
height: 80%;
}

.uploadtranscript__modal textarea{
  min-height: 300px;
}

.uploadtranscript__name{
  font-size: 20px;
  font-weight: bold; 
}

.uploadtranscript__transcript{
  margin-top: 20px;
  font-size: 15px;
}

.masonry {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-auto-rows: 10px;
  gap: 10px;
}

.masonry-item {
  background-color: #f2f2f2;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  background-color: white;
  overflow: hidden;
  cursor: pointer;
  transform: scale(.95);
  transition: .5s all;
}

.masonry-item:hover{
  transform: scale(1);;
}

.masonry-item:nth-child(2n) {
  grid-row: span 20;
}

.masonry-item:nth-child(odd) {
  grid-row: span 15;
}