.details-page-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.details-page-container.fade-in {
  opacity: 1;
}

.details-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 15px;
  border-radius: 8px 8px 0 0;
  width: 100%;
  max-width: 500px;
}

.details-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 0 0 8px 8px;
  width: 100%;
  max-width: 500px;
}

.close-btn {
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.close-btn:hover {
  background-color: #cc0000;
}
