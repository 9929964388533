
.subscription{
  
}

.subscription__no-sub{
  padding: 20px;
  border-radius: 10px;
  margin-top: 40px;
  background-color: white;
}

.subscription__no-sub h3{
  font-weight: bold;
  margin-bottom: 10px;
}

.subscription__has-sub{
  padding: 10px;
  border-radius: 10px;
  margin-top: 40px;
  background-color: white;
}

.subscription__has-sub h3{

}

.subscription__status p{
  margin-top: 20px;
}

.subscription .highlight{
  background-color: goldenrod;
  color: white;
  padding: 1px 5px;
  font-size: 12px;
  border-radius: 5px;
  font-weight: bold;
}