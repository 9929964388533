.react-icons {
  vertical-align: middle;
}

.input-label,
.label {
  font-family: var(--secondary-font);
  font-size: 14px;
  display: block;
  margin-bottom: 5px;
}

.input__label {
  margin-bottom: 8px;
  text-align: left;
  font-weight: bold;
}

.label--error {
  color: #ff374c;
  font-size: 12px;
  font-weight: bold;
}

.btn--calendar{
  background-color: dodgerblue;
color: white;
padding: 4px 20px;
display: block;
width: 100%;
text-align: center;
text-decoration: none;
padding: 10px;
border-radius: 5px;
font-size: 12px;
}

.btn--calendar{
  display: inline-block;
    position: relative;
    color: #000!important;
    font-weight: 600;
    background: #fff;
    font-size: 15px;
    text-decoration: none;
    border: 1px solid transparent;
    padding: 15px 30px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    cursor: pointer;
    outline-color: rgba(0,78,255,0.5);
    text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
    box-shadow: 0 0 0 0.5px rgba(50,50,93,.17), 0 2px 5px 0 rgba(50,50,93,.1), 0 1px 1.5px 0 rgba(0,0,0,.07), 0 1px 2px 0 rgba(0,0,0,.08), 0 0 0 0 transparent!important;
    background-position: -9999px -9999px;
    background-repeat: no-repeat;
    transition: .5s all;
    width: auto;
    border-radius: 10px;
    padding: 5px 10px;
}

.btn--calendar:hover{
}

.radio-label {
  display: inline-block;
  padding-left: 5px;
  vertical-align: middle;
}

.input,
.input_box,
.input-box {
  /* LeadrPro Colors/White */
  background: #ffffff;
  /* LeadrPro Colors/Medium grey */
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  width: 100%;
  padding: 20px 10px;
}

.input--error {
  border: 1px solid #ff374c;
}

.dropdown-font {
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  color: #262626;
}

.btn {
  border-radius: 5px;
  font-size: 13px;
  line-height: 16px;
  border: none;
  text-align: center;
  letter-spacing: 0.03em;
  /* width: 100%; */
  padding: 10px 40px;
  cursor: pointer;
  transition: .5s all;
  background-color: #eee;
}
/* primary button styling */
.btn_primary,
.btn-primary,
.btn--primary {
  background: var(--primary-color);
  background-color: black;
  border-radius: 5px;
  color: white;
  font-size: 13px;
  text-decoration: none;
  line-height: 16px;
  border: none;
  text-align: center;
  width: auto;
  cursor: pointer;
  transition: 0.5s all;
}

.btn--primary:hover {
  background: mediumpurple;
}

/* secondary button styling */
.btn_secondary,
.btn-secondary,
.btn--secondary {
  background: #f2f2f2;
  color: black;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  transition: 0.5s all;
}

.btn-secondary:hover,
.btn--secondary:hover {
  background: mediumpurple;
  color: white;
}

/* third button styling, blue */
.btn-action,
.btn--action {
  background: #7107a8;
  border-radius: 5px;
  color: white;
  font-size: 13px;
  line-height: 16px;
  border: none;
  text-align: center;
  letter-spacing: 0.03em;
  text-decoration: none;
  /* margin-top: 30px; */
  /* width: 100%; */
  padding: 15px;
  cursor: pointer;
  padding-right: 30px;
  padding-left: 30px;
}

/* fourth button styling, black */
.btn-active,
.btn--active {
  background: #262626;
  border-radius: 5px;
  color: white;
  font-size: 13px;
  line-height: 16px;
  border: none;
  text-align: center;
  letter-spacing: 0.03em;
  margin-top: 30px;
  /* width: 100%; */
  padding: 15px;
  cursor: pointer;
}

.btn--full {
  width: 100%;
}

.btn--confirm{
  width: 100%;
  padding: 15px;
  border-radius: 0px;
  color: white;
  background-color: #81CC90;
  transition: .5s all;
}

.btn--cancel:hover{
  background:#ccc;
}

.btn--confirm:hover{
  background-color: #72B780;
}

.btn--apply{
  background-color: black;
  display: inline-block;
}

.btn--apply:hover{
  background-color: mediumpurple;
}

.btn--disabled{
  background-color: #f2f2f2;
}

.btn--decline, .btn--danger{
  background-color: #ef0620;
  color: white;
}

.btn--decline:hover, .btn--danger:hover{
  background-color: #f51830;
}

.btn--dark{
  background-color: black;
  color: white;
}

.btn--small{
  padding: 5px 10px;
}

.textarea{
  font-size: 14px;
}

.form-group,
.form__group {
  margin: 15px 0px;
}

.link {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  align-items: center;
  font-weight: bold;
}

.link-skip {
  text-decoration: none;
  color: #bebebe;
}

.link__dash {
  font-size: 12px;
  text-decoration-line: underline;
  color: #086df6;
  margin-top: 10px;
  cursor: pointer;
}

.link--cancel {
  color: #bebebe;
  text-decoration: none;
}

.form__dropdown {
  width: 240px;
  height: 120px;
  background: #ffffff;
  border-radius: 5px;
}

/* needs to be hover state ?? or on click ?? */
.form__dropdown--selected {
  width: 240px;
  height: 32px;
  top: 0px;
  background: #ffffff;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}

.checkbox--round {
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 3px solid #ddd;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.checkbox--round:checked {
  background-color: gray;
}

.checkbox-wrapper {
  display: block;
  width: 100%;
  margin-bottom: 15px;
}

.checkbox-text {
  display: inline-block;
  vertical-align: middle;
  width: 60%;
  padding-left: 10px;
  margin: 2px;
}
