
.contentstudioitem{
  background-color: #f2f2f2;
  padding: 20px;
  font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    background: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
}

.contentstudioitem {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333; /* Default text color */
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    background: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
}


.contentstudioitem__step-image{

}

.contentstudioitem__btn-generate-wrapper{
  margin-top: 30px;
  display: flex;
  justify-content: center;
  /* background-color: white;
  border-radius: 30px;
  border: solid 1px #ccc;
  overflow: hidden; */
}

.contentstudioitem__btn-generate-wrapper input{
  padding: 10px;
  border: none;
  outline: none;
  border-radius: 30px;
  border: solid 1px #ccc;
}

.contentstudioitem__btn-generate{
  padding: 10px;
  font-weight: bold;
  text-transform: uppercase;
  border: solid;
  border-radius: 30px;
  font-style: italic;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: solid 1px #ccc;
  margin-left: 5px;
}

.contentstudioitem__summary{
  margin-top: 10px;
  font-size: 14px;
  line-height: 1.1;
}

.contentstudioitem__summary svg{
  fill: mediumseagreen;
}

.contentstudioitem__step-image img{
  width: 100%;
  border-radius: 20px;
}

.contentstudioitem__item-menu{
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-top: solid 1px #ddd;
  align-items: center;
}

.contentstudioitem__modifier{
  background-color: white;
  padding: 6px 10px;
  font-size: 12px;
  border: solid 1px #ddd;
  margin-top: 10px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  margin: 5px;
  border-radius: 10px;
}

.contentstudioitem__items{
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
}

.contentstudioitem__item{
  margin: 1%;
  border: solid 2px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 3px 3px 4px #ccc;
  border: none;
  padding: 15px;
  width: 100%;
  font-size: 15px;
  margin-bottom: 5px;
  height: 200px;
  border: solid 1px #ccc;
  overflow: hidden;
  transition: .5s all;
  cursor: pointer;
  width: 48%;
  line-height: 1;
  transform: scale(.95);;
}

.contentstudioitem__item:hover{
  background-color: #eee;
 transform: scale(1);
}

.contentstudioitem__item.full{
  height: auto;
}

.contentstudioitem__item h1{
  /* font-size: 24px; */
  margin-bottom: 20px;
}

.contentstudioitem__item h2{
  /* font-size: 20px; */
  font-weight: 500;
}

.contentstudioitem__item p{
  margin: 10px 0px;
  line-height: 1.5;
}

/* Headings */
.contentstudioitem h1 {
  margin-bottom: 0.5em;
  color: #222;
  border-bottom: none;
  padding-bottom: 0.2em;
  line-height: 1;
}

.contentstudioitem h2 {
  color: #333;
  font-weight: bold;
}

.contentstudioitem h3 {
  margin-top: 1em;
  margin-bottom: 0.5em;
  color: #444;
}

/* Paragraphs */
.contentstudioitem p {
  margin: 0.5em 0;
}

/* Links */
.contentstudioitem a {

}

.contentstudioitem a:hover {
}

/* Code Blocks */
.contentstudioitem pre {
  background: #272822;
  color: #f8f8f2;
  padding: 10px;
  border-radius: 6px;
  overflow-x: auto;
  font-family: 'Courier New', Courier, monospace;
  font-size: 0.95em;
}

.contentstudioitem code {
  background: #f4f4f4;
  color: #d14;
  padding: 2px 4px;
  border-radius: 4px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 0.9em;
}

/* Lists */
.contentstudioitem ul,
.contentstudioitem ol {
  margin: 0px;
  padding-left: 2em;
}

.contentstudioitem li {
  margin: 0.5em 0;
  list-style-type: disc;
}

/* Blockquotes */
.contentstudioitem blockquote {
  border-left: 4px solid #ddd;
  padding-left: 1em;
  margin: 1em 0;
  color: #666;
  font-style: italic;
  background-color: #f9f9f9;
  border-radius: 4px;
}

/* Tables */
.contentstudioitem table {
  border-collapse: collapse;
  width: 100%;
  margin: 1em 0;
}

.contentstudioitem th,
.contentstudioitem td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.contentstudioitem th {
  background-color: #f4f4f4;
  font-weight: bold;
}

/* Horizontal Rule */
.contentstudioitem hr {
  border: 0;
  height: 1px;
  background: #ddd;
  margin: 1.5em 0;
}

/* Images */
.contentstudioitem img {
  max-width: 100%;
  height: auto;
  border-radius: 6px;
  margin: 1em 0;
}

.contentstudioitem__item p{
  font-size: 15px;
}


.contentstudioitem__item-btns{
  display: flex;
}

.contentstudioitem__item-btns > div{
  margin: 5px;
  cursor: pointer;
}

.contentstudioitem__item-copy{
  line-height: 1.7;
  font-size: 15px;
  font-weight: 500;
  font-size: 14px;
  font-weight: bold;
}

.contentstudioite__item-date{
  font-size: 12px;
  font-weight: normal;
  margin-top: 20px;
  margin-bottom: 10px;
}

.contentstudioitem__item-copy:before{
}

.contentstudioitem__item-copy:after{
}

.contentstudioitem__step-name{
  font-size: 30px;
  font-weight: bold;
  margin-top: 0px;
}

.contentstudioitem__step-description{
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
}

.contentstudioitem__step-description svg{
  fill: mediumseagreen;
}

.contentstudioitem__item-preview{
  text-align: center;
}

.contentstudioitem__item-preview .btn{
  border-radius: 15px;
  background-color: #eee;
  color: #000;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: bold;
  border: solid 1px #ccc;
  transition: .5s all;
}

.contentstudioitem__item-preview .btn:hover{
  background-color: #ddd;
}

.contentstudioitem__item-preview .btn svg{
  height: 10px;
}

.contentstudioitem__modifiers{
  display: flex;
}

.contentstudioitem__select{
  display: inline-block;
  padding: 5px 10px;
}

.contentstudioitem__output-type-label{
  margin-left: 10px;
  border: solid 1px #ccc;
  background-color: white;
  padding: 5px 10px;
  border-radius: 30px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  display: inline-block;
}

.contentstudioitem__output-type-label:hover{
  background-color: #eee;
}

.contentstudioitem__output-type-dropdown{
  background-color: white;
  padding: 0px;
  border-radius: 10px;
  box-shadow: 2px 2px 9px #aaa;
  display: inline-block;
  position: absolute;
  top: 120px;
  overflow: hidden;
  border-radius: 10px;
  top: 40px;
  z-index: 5;
  margin-left: -150px;
  min-width: 300px;
}

.contentstudioitem__output-type-dropdown > div{
  background-color: #f2f2f2;
  padding: 5px 15px;
  text-align: center;
  border-bottom: solid 1px #ccc;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}

.contentstudioitem__output-type-dropdown > div:hover{
  background-color: #ddd;
}

.contentstudioitem__details{
  height: 0%;
  padding: 5%;
  position: fixed;
  top: -5000px;
  left: 0px;
  width: 100%;
  z-index: 20000;
  opacity: 0;
  transition: .5s all;
  background-color: white;

}

.contentstudioitem__details.show{
  height: 100%;
  opacity: 1;
  top: 0px;
}

@media screen and (max-width: 890px){
  .contentstudioitem__items{
    flex-direction: column;
  }

  .contentstudioitem__item{
    width: 100%;
  }

  .contentstudioitem__btn-generate-wrapper{
    flex-direction: column;
  }
}