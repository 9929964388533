
.firstassistant{
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: #1d212b;
  background: -moz-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%),-moz-linear-gradient(top, rgba(57,173,219,.25) 0%, rgba(42,60,87,.4) 100%), -moz-linear-gradient(-45deg, #670d10 0%, #092756 100%);
  background: -webkit-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%), -webkit-linear-gradient(top, rgba(57,173,219,.25) 0%,rgba(42,60,87,.4) 100%), -webkit-linear-gradient(-45deg, #670d10 0%,#092756 100%);
  background: -o-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%), -o-linear-gradient(top, rgba(57,173,219,.25) 0%,rgba(42,60,87,.4) 100%), -o-linear-gradient(-45deg, #670d10 0%,#092756 100%);
  background: -ms-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%), -ms-linear-gradient(top, rgba(57,173,219,.25) 0%,rgba(42,60,87,.4) 100%), -ms-linear-gradient(-45deg, #670d10 0%,#092756 100%);
  background: -webkit-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%), linear-gradient(to bottom, rgba(57,173,219,.25) 0%,rgba(42,60,87,.4) 100%), linear-gradient(135deg, #670d10 0%,#092756 100%);
  z-index: 2000000;
  text-align: center;
  align-content: center;
  color: white;
}

.firstassistant__btn-exit{
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.firstassistant__btn-exit .btn{
  background-color: red;
  color: white;
}

.firstassistant__duration-label{
  color: #ff1e54;
}

.firstassistant__timer{
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 22px;
  font-weight: bold;
}

.firstassistant__modal-copy{
  width: 60%;
  margin: 20px auto;
}
.firstassistant__call-btn{
  font-size: 16px;
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px;
    transition: 0.3s;
    opacity: 1;
    cursor: pointer;
    background: transparent;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    font-style: italic;
    padding: 20px 40px;
    border-radius: 10px;
    border: solid 1px #fff;
    margin: auto;
}

.firstassistant__step-name{
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  font-style: italic;
  margin-top: 20px;
}