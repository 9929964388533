.interview-editor-container {
  display: flex;
  height: 100vh;
  font-family: Arial, sans-serif;
}

.left-nav {
  width: 25%;
  background-color: #f8f9fa;
  padding: 20px;
  border-right: 1px solid #ddd;
  overflow-y: auto;
}

.left-nav h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
}

.param-group {
  margin-bottom: 1rem;
}

.param-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.param-group input,
.param-group textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

.param-group select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  background-color: #fff;
}

.right-content {
  flex: 1;
  padding: 20px;
  background-color: #ffffff;
  overflow-y: auto;
  padding-bottom: 10%;;
}

.right-content h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.question-group {
  margin-bottom: 30px;
  padding: 5px;
  border: solid 1px #ccc;
  border-radius: 10px;
  font-weight: bold;
}

.question-group textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  border: none;
  outline: none;
}

.question-group.fade-in {
  animation: fadeIn 0.5s forwards;
}

.question-group.fade-out {
  animation: fadeOut 0.5s forwards;
}

.add-question-btn {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

.add-question-btn:hover {
  background-color: #0056b3;
}

.fade-in {
  animation: fadeIn 0.5s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    height: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
}

.preview-button-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.preview-btn {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  border: solid 1px #ccc;
}

.preview-btn:hover {
  background-color: #f2f2f2;
}

.microphone-icon {
  margin-right: 0.5rem;
}

.status-badge {
  display: inline-block;
  padding: 2px 0.6rem;
  background-color: #f7ecc4;
  color: #212529;
  font-size: 12px;
  font-weight: bold;
  border-radius: 0.5rem;
  text-align: center;
}

.step2-btn {
  display: block;
  width: 100%;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #17a2b8;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

.step2-btn:hover {
  background-color: #138496;
}

.recipients-section {
  font-size: 12px;
}

.recipient-group {
  margin-bottom: 1rem;
}

.recipient-group input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

.recipient-group input[type="text"],
.recipient-group input[type="email"] {
  margin-bottom: 0.5rem;
}

.add-recipient-btn {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

.add-recipient-btn:hover {
  background-color: #218838;
}

.submit-btn {
  display: block;
  width: 100%;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #0056b3;
}

.back-btn {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #6c757d;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

.back-btn:hover {
  background-color: #5a6268;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-content h3 {
  margin-bottom: 15px;
  font-size: 1.2rem;
}

.modal-content input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

.save-btn,
.cancel-btn {
  padding: 10px 20px;
  margin: 5px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.save-btn {
  background-color: #f2f2f2;
}

.save-btn:hover {
  background-color: #ddd;
}

.cancel-btn {
  background-color: #f2f2f2;
  opacity: .6;
}

.cancel-btn:hover {
  opacity: 1;
}

.question-menu {
  margin-top: 5px;
  text-align: right;
}

.delete-question-btn {
  padding: 0.3rem 0.6rem;
  background-color: #ffdde0;
    color: indianred;
  border: none;
  border-radius: 5px;
  font-size: 0.9rem;
  cursor: pointer;
}

.save-question-btn{
  padding: 0.3rem 0.6rem;
  background-color: #3aaf88;
    color: white;
  border: none;
  border-radius: 5px;
  font-size: 0.9rem;
  cursor: pointer;
  margin-left: 5px;
}

.delete-question-btn:hover {
}

.drag-up-btn,
.drag-down-btn {
  padding: 0.3rem 0.6rem;
  background-color: #6c757d;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 0.9rem;
  cursor: pointer;
  margin-right: 0.3rem;
}

.drag-up-btn:hover,
.drag-down-btn:hover {
  background-color: #5a6268;
}

.drag-up-btn:disabled,
.drag-down-btn:disabled {
  background-color: #d6d8db;
  cursor: not-allowed;
}

.move-highlight {
  animation: highlightMove 0.5s ease-in-out;
}

@keyframes highlightMove {
  from {
    background-color: #d1ecf1;
  }
  to {
    background-color: transparent;
  }
}

.question-index {
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #6c757d;
}

.questions-section.fade-out {
  animation: fadeOut 0.5s forwards;
}

.recipients-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.recipients-table th,
.recipients-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  vertical-align: middle;
}

.recipients-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.recipients-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.recipients-table tr:hover {
  background-color: #f1f1f1;
}

.recipients-table th:first-child,
.recipients-table td:first-child {
  width: 30%;
}

.recipients-table th:nth-child(3),
.recipients-table td:nth-child(3) {
  width: 20%;
  text-align: center;
}

.view-status-btn,
.delete-recipient-btn {
  margin-right: 5px;
  padding: 5px 10px;
  font-size: 0.9rem;
  cursor: pointer;
}

.view-status-btn {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 3px;
}

.delete-recipient-btn {
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 3px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal {

}

.modal h3 {
  margin-bottom: 15px;
  font-size: 1.2rem;
}

.modal p {
  margin-bottom: 20px;
  font-size: 1rem;
}
