
.examples{

}

.examples__hero{
  padding: 10%;
  text-align: center;
  background-color: #dce0e580;

  align-items: center;
    padding: 10%;
    padding-top: 4%;
    text-align: center;
    background-color: #aee2ff;
    /* height: 80vh; */
    padding-bottom: 0px;
    display: flex
;
}

.examples__hero-left{
  width: 50%;
  text-align: left;
}

.examples__hero-title{
  font-size: 44px;
  font-weight: bold;
  font-family: 'Poppins';
}

.examples__hero-subtitle{
  margin-top: 30px;
}

.examples__hero-kathie{
  width: 50%;
}

.examples__hero-kathie img{
  width: 100%;
}

.examples__section{
  padding: 10%;
}

.examples__section h2{
  font-size: 30px;
  text-align: center;
  margin-bottom: 40px;
  font-style:italic;
}

.examples__hero-cta{
  margin-top: 30px;
}

.examples__hero-cta .btn{
  border-radius: 0px;
  padding: 20px 50px;
  background: #0f4e8b;
}

.examples__output-types-wrapper{
  padding: 2%;
}

.examples__output-types-wrapper h2{
  font-size: 24px;
}

.examples__output-types{
  padding: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.examples__output-type{
  padding: 20px;
  border: solid 1px #ccc;
  margin: 2px;
  font-weight: bold;
  font-size: 12px;
}

.examples__items{
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: auto;
}

.examples__item{
  margin: 1%;
  box-shadow: 4px 4px 9px #ccc;
  border-radius: 10px;
}

.examples__item img{
  width: 100%;
  border-radius: 10px;
}

.examples__bottom-cta{
  background-color: #0f4e8b;
  color: white;
  text-align: center;
}