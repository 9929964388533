
.outputtype__editor-wrapper{
  display: flex;
  flex-direction: row;
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 10px;
  border: solid 1px #ddd;
  font-size: 14px;
}

.outputtype__editor-wrapper label{
  font-size: 12px;
  font-weight: bold;
}

.outputtype__editor-wrapper textarea{
  min-height: 120px;
  padding: 5px;
}

.outputtype__editor-wrapper > div{
  width: 50%;
}

.outputtype__editor-preview{
  padding: 0px 15px;
}

.outputtype__editor-response{
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  line-height: 2;
  max-height: 400px;
  overflow: auto;
}