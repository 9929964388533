
.upgrade{
  background-color: #eee;
}

.upgrade__header{
  padding: 5%;
  text-align: center;
}
.upgrade__header h1{
  font-size: 44px;
  font-weight: bold;
}

.upgrade .highlight{
  color: #6366f1;
}

.upgrade .btn--confirm{
  background-color: goldenrod;
  color: white;
  margin-top: 50px;
  font-size: 12px;
  font-weight: bold;
}
.upgrade__plans{
  display: flex;
  justify-content: center;
}

.upgrade__plan{
  border: solid 1px #ccc;
  border-radius: 10px;
  padding: 20px;
  margin: 1%;
  width: 33%;
  text-align: left;
  background-color: white;
}

.upgrade__plan-name{
  font-size: 32px;
  font-weight: bold;
}

.upgrade__plan-price{
  font-size: 14px;
  margin-top: 15px;
}

.upgrade__plan-price-amount{
  font-size: 30px;
  font-weight: bold;
}

.upgrade__plan-features{
  margin-top: 50px;
}

.upgrade__plan-features ul{
  margin: 0px;
  padding: 0px;
}

.upgrade__plan-features li{
  margin-bottom: 15px;
}

.upgrade__plan-features svg{
  height: 7px;
}

.upgrade__plan-feature{
  font-size: 14px;
}

.upgrade__plan-btns{
  margin-top: 50px;
}

.upgrade__plan-btns .btn{
  background-color: goldenrod;
  color: white;
  padding: 20px;
  width: 100%;
  padding: 10px;
  font-weight: bold;
  font-size: 16px;
}

.upgrade__disclosure{
  font-size: 11px;
  font-style: italic;
  margin-top: 10px;
}