
.homepagejourney{

}

.homepagejourney .highlight{
  color: #8c52ff;
}

.homepagejourney__hero{
  padding: 5%;
  display: flex;
}

.homepagejourney__hero-subtext{
  font-size: 30px;
  line-height: 1.5;
  margin-top: 30px;
}

.homepagejourney__hero img{
  width: 100%;
}

.homepagejourney__hero-left{
 width: 45%;
}

.homepagejourney__hero-right{
  width: 55%;
  padding-left: 5%;
}

.homepagejourney__hero-left-copy{
  font-size: 62px;
  font-weight: bold;
  line-height: 1.1;
  text-transform: uppercase;
  font-family: 'Space Grotesk';
}

.homepagejourney__hero-get-started{
  background-color: #cdfe18;
  color: black;
  text-transform: uppercase;
  padding: 20px 50px;
  border:solid 2px;
  font-size: 19px;
  margin-top: 40px;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
}

.homepagejourney__steps{
  margin-bottom: 10px;
}

.homepagejourney__steps img{
  width: 100%;
}

.homepagejourney__section0{
  display: flex;
  align-items: center;
  padding: 5%;
}

.homepagejourney__section0-left{
  width: 50%;
}

.homepagejourney__section0-right{
  width: 50%;
}

.homepagejourney__section0 img{
  width: 100%;
}

.homepagejourney__section1{
  background-color: #8c52ff;
  color: white;
  display: flex;
  padding: 5% 10%;
  align-items: center;
}

.homepagejourney__section1-left{
  width: 40%;
  text-align: center;
}

.homepagejourney__section1-left img{
  width: 70%;
}

.homepagejourney__section1-right{
  width: 60%;
}

.homepagejourney__section1-right-title{
  font-size: 76px;
  font-weight: bold;
  line-height: 1.1;
  text-transform: uppercase;
  font-family: 'Space Grotesk';
}

.homepagejourney__section1-right-copy{
  margin-top: 20px;
}

.homepagejourney__section2{
  display: flex;
  align-items: center;
  padding: 5% 10%;
}

.homepagejourney__section2-left{
  width: 50%;
  text-align: center;
}

.homepagejourney__section2-left img{
  width: 80%;
}

.homepagejourney__section2-right{
  width: 50%;
  padding-left: 2%;
}

.homepagejourney__section2-right-title{
  font-size: 76px;
  font-weight: bold;
  line-height: 1.1;
  text-transform: uppercase;
  font-family: 'Space Grotesk';
  margin-bottom: 20px;
}

@media screen and (max-width: 890px){
  .homepagejourney__section1{
    padding: 20px;
    flex-direction: column;
  }

  .homepagejourney__section1-left{
    width: 100%;
  }

  .homepagejourney__section1-right{
    width: 100%;
  }

  .homepagejourney__section2{
    flex-direction: column;
    padding: 20px;
  }

  .homepagejourney__section2-left{
    width: 100%;
  }

  .homepagejourney__section2-right{
    width: 100%;
  }

  .homepage__section1{
    flex-direction: column;
  }

  .homepage__section1-left{
    width: 100%;
  }

  .homepage__section1-right{
    width: 100%;
  }
  
  .homepage__section2{
    flex-direction: column;
  }

  .homepage__section2-screenshots{
    flex-direction: column;
  }

  .homepagejourney__hero{
    flex-direction: column;
    text-align: center;
  }

  .homepagejourney__hero-left{
    width: 100%;
  }

  .homepagejourney__hero-left-copy{
    font-size: 43px;
  }

  .homepagejourney__hero-right{
    width: 100%;
    text-align: right;
  }

  .homepagejourney__section2-right-title{
   font-size: 3rem; 
  }

  .homepagejourney__section2-right{
    text-align: center;
  }

  .homepagejourney__section1-right-title{
    font-size: 3rem;
  }

  .homepage__section1-different{
    flex-direction: column;
  }

  .homepage__section1-different > div{
    width: 100%;
  }

  .homepage__section2-screenshots{
    flex-direction: column;
  }

  .homepage__section2-screenshot{
    width: 100%;
  }

  .homepage__section2-screenshot img{
    width: 100%;
  }
}