

.dashboardoutputtypes{

}

.dashboardoutputtypes__interviews{
  display: flex;
  flex-wrap: wrap;
}

.dashboardoutputtypes__interview{
  width: 30%;
  margin: 1%;
  background-color: white;
  overflow: hidden;
  box-shadow: 2px 2px 9px #ccc;
  padding: 0px;
  border-radius: 10px;
  transform: scale(.97);
  transition: .5s all;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dashboardoutputtypes__interview:hover{
  transform: scale(1);
}

.dashboardoutputtypes__interview-title{
  font-size: 16px;
  font-weight: bold;
}

.dashboardoutputtypes__interview-image{
  overflow: hidden;
  height: 100px;
}

.dashboardoutputtypes__interview-image img{
  height: 100px;
  width: auto;
  max-width: unset;
}

.dashboardoutputtypes__how-it-works{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.dashboardoutputtypes__how-it-works > div{
  box-shadow: 2px 2px 9px #ccc;
  padding :15px;
  border: solid 1px #ccc;
  font-size: 12px;
  margin: 1%;
  width: 31%;
  border-radius: 10px;
}

@media screen and (max-width: 890px){
  .dashboardoutputtypes__interview{
    width: 100%;
  }
}