.admin-section {
  margin: 20px 0;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.admin-section h2 {
  margin-bottom: 15px;
  font-size: 1.5rem;
  color: #333;
  font-family: 'Poppins';
  font-weight: bold;
}

.create-interview-btn {
  margin-bottom: 15px;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  border: solid 1px #ccc;
}

.create-interview-btn:hover {
  background-color: #f2f2f2;
}

.interview-table {
  width: 100%;
  border-collapse: collapse;
}

.interview-table th,
.interview-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  vertical-align: middle;
}

.interview-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.view-btn,
.edit-btn {
  padding: 5px 10px;
  margin-right: 5px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.view-btn {
  background-color: #28a745;
  color: white;
}

.view-btn:hover {
  background-color: #218838;
}

.edit-btn {
  background-color: #ffc107;
  color: black;
}

.edit-btn:hover {
  background-color: #e0a800;
}

.interviewlinklist__date{
  font-size: 12px;
}

.interviewlinklist__title{
  font-size: 16px;
  font-weight: bold;
}

.interviewlinklist__description{
  margin-top: 10px;
  font-size: 12px;
}