
.contentstudio{

}

.contentstudio__copy{
  margin-bottom: 20px;
}

.contentstudio__item{
  transition: .5s all;
  cursor: pointer;
}

.contentstudio__item:hover{
  transform: scale(1.05);
}

.contentstudio h1{
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.contentstudio__output-types{
  display: flex;
  margin-top: 30px;
  flex-direction: column;
}

.contentstudio__output-type{
  background-color: white;
  padding: 30px 20px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: bold;
  margin: 10px;
  align-items: center;
  cursor: pointer;
  transition: .5s all;
  background-color: #dce0e580;
}

.contentstudio__output-type:hover{
  background-color: #cae3f9;
}

.contentstudio__output-type-name{
font-size: 22px;
}

.contentstudio__output-type-description{
  margin-top: 20px;
  font-size: 14px;
  font-weight: normal;
}

.dashboard__journey-interview-date{
  margin-top: 20px;
  background-color: #111;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 30px;
  display: inline-block;
}