.contenteditor{

}

.contenteditor__wrapper{
  display: flex;
  flex-wrap: wrap;
  height: 80vh;
}

.contenteditor__input{
  width: 30%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  border-radius: 15px;
  height: 100%;
}

.contenteditor__input-messages{
  height: 80%;
  overflow: auto;
  padding: 20px;
}

.contenteditor__output{
  width: 69%;
  padding: 10px 40px;
  margin-left: 1%;
  background-color: white;
  border-radius: 15px;
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.contenteditor__output-markdown{
  padding-bottom: 100px;
  height: 90%;
  overflow: auto;
}

.contenteditor__content{
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
}

.contenteditor__output p{
  font-size: 15px;
  margin-bottom: 25px;
}

.contenteditor__output h2{
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  font-family: 'Poppins';
  margin-top: 20px;
}

.contenteditor__output h3{
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
  font-family: 'Poppins';
  margin-top: 20px;
}

.contenteditor__output ul{
  list-style-type: circle;
  margin-left: 20px;
  margin: 30px;

}

.contenteditor__output-menu{
  display: flex;
}

.contenteditor__output-revisions{
  padding: 10px;
}