
.homepage{
  font-family: 'Roboto';
}

p{
  line-height: 1.5;
  font-size: 1.125rem;
}

.homepage .highlight{
  color: rgba(152, 128, 220, 1);
}

.homepage__hero{
  text-align: center;
  display: flex;
  justify-content: space-between;
  text-align: left;
}

.homepage__hero-left{
  width: 30%;
}

.homepage__hero-left img{
}

.homepage__hero-right{
  width: 60%;
  padding: 40px 120px;
}

.homepage__hero-logo{
  text-align: right;
}

.homepage__hero-logo img{
  max-width: 250px;
}
.homepage__hero-title{
  font-size: 3rem;
  font-weight: bold;
  text-align: left;
  margin-top: 40px;
  line-height: 1.3;
  font-family: 'Space Grotesk';
}

.homepage__hero-subtitle{
  font-size: 30px;
  margin-top: 40px;
  text-align: left;
  line-height: 1.5;
  font-family: 'Syne';
}

.homepage__hero-people{
  margin-top: 40px;
  display: flex;
}

.homepage__hero-people-image-wrapper{
  padding: 0px 20px;
  border-right: solid 4px;
}

.homepage__hero-people-image-wrapper img{
  width: 100%;
}

.homepage__hero-people-count{
  font-size: 60px;
}

.homepage__section1{
  display: flex;
  padding: 40px 15%;
}

.homepage__section1-title{
  font-size: 40px;
  font-weight: bold;
  line-height: 1.1;
  font-family: 'Space Grotesk';
}

.homepage__section1-different{
  display: flex;
  margin-top: 50px;
}

h3{
  font-family: 'Space Grotesk';
  font-size: 1.5rem;
}

.homepage__section1-different h3{
  font-family: 'Space Grotesk';
  font-size: 1.5rem;
}

.homepage__section1-different > div{
  width: 50%;
  padding: 20px;
}

.homepage__section1-different-copy{
  margin-top: 30px;
  line-height: 1.5;
  font-size: 1.125rem;
}

.homepage__section2{
  padding: 40px 15%;
}

.homepage__section2-screenshots{
  display: flex;
  margin-top: 50px;
}

.homepage__section2-screenshot{
  width: 31%;
}

.homepage__section3{
  background-color: rgba(188,201,245,1);
  padding: 50px 15%;
  text-align: center;
}

.homepage__section3-title{
  font-size: 4rem;
  text-align: center;
  font-family: 'Space Grotesk';
  font-weight: bold;
}

.homepage__section3-copy{
  margin-top: 30px;
  font-size: 1.5rem;
  line-height: 1.5;

}

.homepage__section3-copyright{
  font-size: 12px;
  margin-top: 100px;
}
.wave-divider{
  width:calc(812% + 1.3px);height:18px;transform:none;
}
.wave{
  fill:rgb(188, 201, 245);
}

.homepage__hero-btn-wrapper{
  margin-top: 40px;
}

.homepage__hero-btn{
  padding: 20px 32px;
  border: solid 2px;
  border-radius: 0px;
  background-color: white;
  font-size: 1.25rem;
  min-width: 120px;
}

.homepage__hero-h1{
  font-size: 50px;
  font-weight: bold;
  font-family: poppins;
}

.homepage__hero-interested-in{
  margin-top: 20px;
  font-size: 16px;
}

.homepage__hero-btns{
  margin-top: 20px;
}

.homepage__hero-btn{

}

.homepage__hero-btn--primary{
  background-color: #5e17eb;
  color: white;
  transition: .5s all;
}

.homepage__hero-btn--primary:hover{
  background-color: #44189e;
  color: white;
}

.homepage__hero-ph{
  margin-top: 30px;
}

.homepage__media-logos{
  padding: 2%;
  text-align: center;
  background-color: black;
}

.homepage__media-logos img{

}

@media screen and (max-width: 890px){
  .homepage__hero-btn{
    display: block;
    margin: 20px 0px;
  }
}